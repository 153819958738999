import React from "react";
import { Spinner } from "react-bootstrap";
import { VIEWER_URL } from "../../utils/consts";
import * as styles from "./HelloIFrame.module.css";

interface IProps {
  productId: string;
}

const HelloIFrame: React.FC<IProps> = ({ productId }) => {
  return (
    <div className={styles.container}>
      <iframe
        title="HelloAR Viewer"
        src={`${VIEWER_URL}?id=${productId}`}
        height="100%"
        width="100%"
        allow="xr-spatial-tracking;fullscreen;"
        frameBorder="0"
      ></iframe>
      <div className={styles.loaderContainer}>
        <Spinner animation="grow" />
      </div>
    </div>
  );
};

export default HelloIFrame;
