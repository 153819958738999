import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "../styles/360-catalog.scss";
import "../styles/product-page.scss";
import HelloIFrame from "../components/HelloIFrame";
import { findThumbnail } from "../utils/images";
import { Link } from "gatsby";
import ButtonBanner from "../components/ButtonBanner";

const Product = (props) => {
  const productData = props.pageContext.data;
  const thumbnail = findThumbnail(productData);
  return (
    <Layout>
      <Helmet>
        <title>{`${productData.title} - HelloAR`}</title>
        <meta name="description" content={`${productData.meta_description}`} />
        <meta name="keywords" content={`${productData.keywords}`} />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content={thumbnail} />
      </Helmet>
      <div className="container-fluid catalog">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <div className="container">
          <h1 className="h1 text-dark product-title">{productData.name}</h1>
          <h2 className="bt-1 text-dark mb-4">{productData.description}</h2>
          <div className="iframe-container-product">
            <HelloIFrame productId={productData._id} />
          </div>
        </div>
      </div>
      <div className="spacer" />
      <div className="sampleproduct section-6  mt-5">
        {/* <h1 className="bta text-center">
              No lock-in, No upfront cost, pay as you go
            </h1> */}
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
      </div>
      <div className="spacer" />
    </Layout>
  );
};

export default Product;
